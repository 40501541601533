import { Link } from "react-router-dom";
import classes from "./index.module.css";

const Button = ({ children }) => {
  return (
    <>
      <Link to="/wallet-index" className={classes.btn}>
        {children}
      </Link>
    </>
  );
};

export default Button;
