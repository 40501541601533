import classes from "./wallets.module.css";
import { useEffect, useState } from "react";
import Data from "../Data/data";
import { WalletLinks } from "../../constants";

const Wallets = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [wallet, setWallet] = useState("");
  const [modal, setModal] = useState("");

  const handleClick = (span) => {
    setModal(true);
    setWallet(span);
  };

  return (
    <>
      {modal && <Data wallet={wallet} setModal={setModal} />}
      <div className={classes.main}>
        <div className={classes.top}>
          <h1>Decentralised Wallet Applications</h1>
          <p className={classes.text}>
            Open protocol for connecting Wallets to Dapps
          </p>

          <div id="cover" className={classes.cover}>
            {WalletLinks.map((item, index) => (
              <button
                onClick={() => handleClick(item.span)}
                to={item.span}
                className={item.classname}
                key={index}
                type="link"
              >
                <span>{item.span}</span>
              </button>
            ))}
          </div>

          <div className={classes.footer}>
            <p>
              <a href="mailto:info@walletconnectdapps.online">
                info@walletconnectdapps.online
              </a>
            </p>
            <p>Copyright &copy; 2021 All rights Reserved</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallets;
