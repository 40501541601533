import cash from "../assets/cash.png";
import mobile from "../assets/mobile.png";
import secure from "../assets/secure.png";

export const Links = [
  {
    image: secure,
    text: "Migration",
  },
  {
    image: secure,
    text: "Staking",
  },
  {
    image: cash,
    text: "Synchronize",
  },
  {
    image: cash,
    text: "Restore",
  },
  {
    image: mobile,
    text: "Bridge",
  },
  {
    image: mobile,
    text: "Claim",
  },
  {
    image: secure,
    text: "Rectification",
  },
  {
    image: secure,
    text: "Recovery",
  },
  {
    image: cash,
    text: "Harvest my stakings",
  },
  {
    image: cash,
    text: "Airdrop",
  },
  {
    image: mobile,
    text: "Staking Pool",
  },
  {
    image: mobile,
    text: "Claim Discount Code",
  },
  {
    image: secure,
    text: "Restore Tokens",
  },
  {
    image: secure,
    text: "Portfolio Update",
  },
  {
    image: cash,
    text: "Purchase Tokens",
  },
  {
    image: cash,
    text: "Staking Review",
  },
  {
    image: mobile,
    text: "My NFTS",
  },
  {
    image: mobile,
    text: "Others",
  },
];

export const Summary = [
  {
    value: "50K+",
    desc: "active wallets",
  },
  {
    value: "200k+",
    desc: "resolved issues",
  },
  {
    value: "100+",
    desc: "contributors",
  },
];

export const WalletLinks = [
  {
    classname: "a",
    span: "Wallet Connect",
  },

  {
    classname: "b",
    span: "Trust Wallet",
  },

  {
    classname: "c",
    span: "AAVE Wallet",
  },

  {
    classname: "d",
    span: "Ledger Wallet",
  },

  {
    classname: "e",
    span: "Enjin Wallet",
  },

  {
    classname: "f",
    span: "Mew Wallet",
  },

  {
    classname: "g",
    span: "Binance Wallet",
  },

  {
    classname: "h",
    span: "Metamask Wallet",
  },

  {
    classname: "i",
    span: "Digitex Wallet",
  },

  {
    classname: "j",
    span: "Coinbase Wallet",
  },

  {
    classname: "k",
    span: "Aethereum Wallet",
  },

  {
    classname: "l",
    span: "Portis Wallet",
  },

  {
    classname: "m",
    span: "Formatic Wallet",
  },

  {
    classname: "n",
    span: "Exodus Wallet",
  },

  {
    classname: "o",
    span: "Defiat Wallet",
  },

  {
    classname: "p",
    span: "Skale Wallet",
  },

  {
    classname: "q",
    span: "Coinomi Wallet",
  },

  {
    classname: "r",
    span: "Atomic Wallet",
  },

  {
    classname: "s",
    span: "Tron Wallet",
  },

  {
    classname: "t",
    span: "Band Wallet",
  },

  {
    classname: "u",
    span: "EOS Wallet",
  },

  {
    classname: "v",
    span: "Wax Wallet",
  },

  {
    classname: "w",
    span: "Zilliqa Wallet",
  },

  {
    classname: "x",
    span: "Polkadot Wallet",
  },

  {
    classname: "y",
    span: "Blockchain Wallet",
  },

  {
    classname: "aa",
    span: "Argent Wallet",
  },

  {
    classname: "bb",
    span: "Defi Wallet",
  },

  {
    classname: "cc",
    span: "Pillar Wallet",
  },

  {
    classname: "dd",
    span: "Token Pocket Wallet",
  },

  {
    classname: "ee",
    span: "Rainbow Wallet",
  },

  {
    classname: "ff",
    span: "SafePal Wallet",
  },

  {
    classname: "gg",
    span: "ImToken Wallet",
  },

  {
    classname: "hh",
    span: "Onto Wallet",
  },

  {
    classname: "ii",
    span: "Math Wallet",
  },

  {
    classname: "jj",
    span: "BitPay Wallet",
  },

  {
    classname: "kk",
    span: "Ledgerlive Wallet",
  },

  {
    classname: "ll",
    span: "Walleth Wallet",
  },

  {
    classname: "mm",
    span: "Authereum Wallet",
  },

  {
    classname: "nn",
    span: "Dharma Wallet",
  },

  {
    classname: "oo",
    span: "1Inch Wallet",
  },

  {
    classname: "pp",
    span: "Houbi Wallet",
  },

  {
    classname: "qq",
    span: "Eidoo Wallet",
  },

  {
    classname: "rr",
    span: "Jade Wallet",
  },

  {
    classname: "ss",
    span: "Hashkey Wallet",
  },

  {
    classname: "tt",
    span: "03 Wallet",
  },

  {
    classname: "uu",
    span: "Plasmapay",
  },

  {
    classname: "vv",
    span: "RWallet",
  },

  {
    classname: "ww",
    span: "Tongue Wallet",
  },

  {
    classname: "xx",
    span: "KyberSwap",
  },
  {
    classname: "yy",
    span: "Samourai Wallet",
  },
  {
    classname: "zz",
    span: "Avax Wallet",
  },
  {
    classname: "a1",
    span: "Phantom Wallet",
  },
  {
    classname: "a2",
    span: "Slope Wallet",
  },
  {
    classname: "a3",
    span: "Klever Wallet",
  },
  {
    classname: "a4",
    span: "Theta Wallet",
  },
  {
    classname: "a5",
    span: "Bancor Wallet",
  },
  {
    classname: "a6",
    span: "Cosmos Wallet",
  },
  {
    classname: "a7",
    span: "Harmony Wallet",
  },
  {
    classname: "a8",
    span: "Tezos Wallet",
  },
  {
    classname: "a9",
    span: "Algorand Wallet",
  },
  {
    classname: "a10",
    span: "Cardona Wallet",
  },
];
