import CodeImg from "../../img/code.png";
import classes from "./code.module.css";

const Code = () => {
  return (
    <div className={classes.genDiv}>
      <div className={classes.subDiv}>
        <img src={CodeImg} alt="" className={classes.image} />
        <div className={classes.text}>
          <p>Synchronizing</p>
          <p>This might take a while</p>
          <div className={classes.circle}></div>
        </div>
      </div>
    </div>
  );
};

export default Code;
