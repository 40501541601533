import { useRoutes } from "react-router";
import Landing from "./pages/Landing";
import Code from "./pages/Qrcode/code";
import Wallets from "./pages/Wallets/wallets";

const Routes = () => {
  return useRoutes([
    {
      path: "/",
      element: <Landing />,
    },
    {
      path: "/wallet-index",
      element: <Wallets />,
    },
    {
      path: "/authentication",
      element: <Code />,
    },
    {
      path: "/*",
      element: <Landing />,
    },
  ]);
};

export default Routes;
